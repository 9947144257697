import { BlogPostAuthor } from "./types";

export const fullUserName = (author?: BlogPostAuthor): string => {
  const nameString =
    author && author.first_name && author.last_name
      ? `${author.first_name} ${author.last_name}`
      : "Blue Beck";
  return nameString;
};

export const convertStringToSlug = (stringToConvert: string): string => {
  const slug = stringToConvert
    .toLowerCase()
    .replace(/\s/g, "-")
    .replace(/,/g, "")
    .replace(/:/g, "")
    .replace(/;/g, "")
    .replace(/!/g, "")
    .replace(/"/g, "")
    .replace(/'/g, "")
    .replace(/\?/g, "");
  return slug;
};
